<template>
  <div class="footer">
    <div class="footer-img">
      <img src="https://dsdj.obs.cn-north-1.myhuaweicloud.com/img/qrcode1.jpg" alt="">
      <img src="https://dsdj.obs.cn-north-1.myhuaweicloud.com/img/qrcode2.jpg" alt="">
      <img src="https://dsdj.obs.cn-north-1.myhuaweicloud.com/img/qrcode3.jpg" alt="">
    </div>
    <div class="footer-info">
      <span>Copyright © 2023</span>
      <span style="margin: 0 .15rem 0 .1rem">东营市商业大厦责任有限公司</span>
      <span>客服电话：0546-8210681</span>
      <span style="margin-right: .2rem">All Rights Reserved.</span>
      <span>鲁ICP备2023027988</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "navFooter"
}
</script>

<style scoped lang="less">
  .footer{
    width: 100%;
    height: 3.6rem;
    background-color: #0F35E2;
    box-sizing: border-box;
    padding: .4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-img{
      width: 4rem;
      display: flex;
      justify-content: space-between;

      img{
        width: 1rem;
        height: 1rem;
      }
    }

    &-info{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      font-size: .28rem;
      margin-top: .2rem;
    }
  }
</style>
